import React from 'react';
import { PageWrapper } from "~components/Core";
import HeroSection from "~sections/Common/Hero";
import PageDetails from "~sections/PageDetails";
import FooterSection from "~sections/Common/Footer";
import Logo from "~image/logo/Sounetu-Logo-CMYK.png"
import HeroBg from "~image/heroimages/detailsPage/hero_bg.jpg"
import heroOverlay from "~image/heroimages/detailsPage/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function Service({
  pageContext,
}) {
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <HeroSection
        title=""
        subtitle=""
        text=""
        HeroBg={HeroBg}
        Overlay={heroOverlay}
      />
      <PageDetails 
      title={pageContext.title} 
      image={pageContext.image} 
      type={pageContext.type} 
      body={pageContext.body} />
      <FooterSection/>
    </PageWrapper>
  );
}
