import React from 'react'
import SideBar from "./style"
import { Link } from '~components'
import { useStaticQuery, graphql } from "gatsby"
import Data from "~data-yaml/types.yml"

export default function SideBarSection(props){
const st = props.type
const data = useStaticQuery(graphql`
query sideBar {
  allMdx {
    edges {
      node {
        frontmatter {
          title
          type
        }
        slug
      }
    }
  }
}`
)
const filtered = data.allMdx.edges.filter(edges => (edges.node.frontmatter.type == props.type))
return(
<SideBar>
     <SideBar.Widgets>
    <SideBar.Title>{Data[props.type]+":"}</SideBar.Title>
    <SideBar.RecentPost>
    {filtered.map((node,i) => {
      return (
        <SideBar.RecentPostList key={"sb"+i}>
        <Link to={"/"+node.node.slug}>
          <SideBar.RecentPostTitle>{node.node.frontmatter.title}</SideBar.RecentPostTitle>
        </Link>
      </SideBar.RecentPostList>
      )
    })}
    </SideBar.RecentPost>
  </SideBar.Widgets>
 
  </SideBar>
)
}