import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from "./style"

export default function HeroSection({ title, subtitle, text, HeroBg, Overlay }) {
  return (
    <Hero
      backgroundImage={HeroBg} backgroundOverlay={Overlay}>
      <Container>
        <Row className="align-items-center justify-content-center justify-content-md-start">
          {/* Welcome content Area */}
          <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1">
            <Hero.Content mb="40px" mbMD="40px" mt="30px" mtLG="0">
              <Hero.Subtitle as="h3" fontColor="#fee856" mb="28px">{subtitle}</Hero.Subtitle>
              <Hero.Title as="h1" fontColor="#fff">{title}</Hero.Title>
              <Hero.Text>{text}</Hero.Text>
            </Hero.Content>
          </Col>
          {/*/ .Welcome Content Area */}
        </Row>
      </Container>
    </Hero>
  )
}