import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components'
import Details from './style'
import Sidebar from '~sections/Common/Sidebar'
import contact from "~data-yaml/contact.yml"

const ServiceDetails = ({ body, title, image, type }) => {
  const iName = image
  const data = useStaticQuery(graphql`
  query serviceImage($image: String ! = "image/services/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            publicURL
          }
        }
      }
    }
`)

const iData = (name) => {
    let iDat = undefined
    for (let i = 0; i < data.allFile.edges.length; i++) {
      if (data.allFile.edges[i].node.base == name) {
        iDat = data.allFile.edges[i].node.publicURL
      }
    }
    return iDat
  }
  return (
    <Details backgroundColor="#f9fafc">
      <Details.Box pb="60px" pbMD="80px" pbLG="130px">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-lg-7">
              <Details.Box mb="30px" mbLG="55px">
                <Details.Title as="h1">{title}</Details.Title>
              </Details.Box>
              <Details.Box>
                <Details.Image mb="35px" mbLG="55px">
                  <img className="w-100" src={iData(iName)} alt="Blog" layout="constrained" placeholder="blurred" />
                </Details.Image>

                <MDXRenderer>{body}</MDXRenderer>

                <Details.Tag mt="30px">
                  <Details.SubTitle mr="25px" as="h4">Share:</Details.SubTitle>
                  <Details.SocialList>
                    {contact.social_share.map((item, i) => {
                      return (
                        <li key={"sd"+i}>
                          <Link to={item.link}>
                            <i className={item.icon} />
                          </Link>
                        </li>
                      )
                    })}
                  </Details.SocialList>
                </Details.Tag>

              </Details.Box>
            </Col>
            <Col xs="12" className="col-xl-4 offset-xl-1 col-lg-5">
              <Sidebar type={type} />
            </Col>
          </Row>
        </Container>
      </Details.Box>
   
    </Details>

  )
}
export default ServiceDetails